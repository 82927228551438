import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase } from '../content-site';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button } from '@material-ui/core';

function VeiculosRecentes(data) {
    const veiculos = data.veiculos;
    const listVeiculos = veiculos.map((v) =>
        <Link to={"/veiculo/" + v.id} key={v.id} >
            <div className="item" >
                <div className="car-item text-center">
                    <div className="car-image">
                        {
                            v.imagens.length > 0 ?
                                v.imagens[0].fileThumb !== undefined ?
                                    <img className="img-fluid" src={v.imagens[0].fileThumb} alt=""></img>
                                    :
                                    <img className="img-fluid" src={v.imagens[0].fileUrl} alt=""></img>
                                : <img className="img-fluid" src="/img/car_default.png" alt='imagem veículo'></img>
                        }
                        <div className="car-overlay-banner"></div>
                    </div>
                    <div className="car-list">
                        <ul className="list-inline">
                            <li><i className="fa fa-registered"></i>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</li>
                            <li><i className="fa fa-cog"></i>{formatCambio(v.vecambio)}</li>
                            <li><i className="fa fa-dashboard"></i>{v.vequilometragem} KM</li>
                        </ul>
                    </div>
                    <div className="car-content">
                        <span>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</span>
                        <Link to={"/veiculo/" + v.id}>{v.vefipe_marca} {v.vefipe_name}</Link>
                        <div className="separator"></div>
                        <div className="price">
                            <span className="new-price">{formatMoney(v.vevalor)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
    
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
    
            }
        },
    };

    return <OwlCarousel className='owl-theme'  {...options} nav>{listVeiculos}</OwlCarousel>
}


function ImagensLoja(data) {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    const imagens = data.images;
    const listImagens = imagens.map((key) =>
        <div key={key}><img className="img-responsive center-block" src={key} alt={"Banner " + data.revenda.penome_fantasia} /></div>
    );
    return <Slider {...settings}>{listImagens}</Slider>;

}

class Inicio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    items.push(document);
                });
                if (this.mounted) {
                    this.setState({
                        instagramData: items
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                        items.push(document.imagem_url);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        bannerData: items
                    });
                }
            });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        if (this.state.revenda.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <div
                    id="rev_slider_3_1_wrapper"
                    className="rev_slider_wrapper fullwidthbanner-container rev_slider_3_1_wrapper"
                    data-alias="car-dealer-05"
                    data-source="gallery"
                    >

                    <div
                        id="rev_slider_3_1"
                        className="rev_slider fullwidthabanner"
                        style={{ display: "none" }}
                        data-version="5.3.0.2"
                    >
                        <ul>

                        <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img01.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img01.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img02.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img02.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img03.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img03.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            {/* <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img04.png"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img04.png"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img05.png"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img05.png"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li> */}

                        </ul>
                        <div
                            className="tp-bannertimer tp-bottom"
                            style={{ visibility: "hidden !important" }}
                        ></div>
                    </div>
                </div>

                <section className="welcome-block objects-car page-section-ptb white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span>Bem-vindo ao nosso site </span>
                                    <div className='title-content'> 
                                        <h2 className='letter v'>V</h2>
                                        <h2 className='letter e'>e</h2>
                                        <h2 className='letter i'>í</h2>
                                        <h2 className='letter c'>c</h2>
                                        <h2 className='letter u'>u</h2>
                                        <h2 className='letter l'>l</h2>
                                        <h2 className='letter o'>o</h2>
                                        <h2 className='letter s'>s</h2>
                                        <div className='margin'></div>
                                        <h2 className='letter n'>n</h2>
                                        <h2 className='letter o2'>o</h2>
                                        <h2 className='letter v2'>v</h2>
                                        <h2 className='letter o3'>o</h2>
                                        <h2 className='letter s2'>s</h2>
                                        <div className='margin'></div>
                                        <h2 className='letter e2'>e</h2>
                                        <div className='margin'></div>
                                        <h2 className='letter s3'>s</h2>
                                        <h2 className='letter e3'>e</h2>
                                        <h2 className='letter m'>m</h2>
                                        <h2 className='letter i2'>i</h2>
                                        <h2 className='letter n2'>n</h2>
                                        <h2 className='letter o4'>o</h2>
                                        <h2 className='letter v3'>v</h2>
                                        <h2 className='letter o5'>o</h2>
                                        <h2 className='letter s4'>s</h2>
                                    </div>
                                <div className="separator"></div>
                                    <p>IMPÉRIO MULTIMARCAS: A SUA LOJA DE CONFIANÇA PARA ENCONTRAR UMA AMPLA VARIEDADE DE CARROS DE DIVERSAS MARCAS, COM QUALIDADE E PREÇOS IMBATÍVEIS.</p> </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="feature-car bg-recentes bg-overlay-black-70 page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span className="text-white">Confira nosso estoque</span>
                                    <div className='recentes'>
                                        <h2 className='text-white letter v4'>V</h2>
                                        <h2 className='text-white letter e4'>e</h2>
                                        <h2 className='text-white letter i3'>í</h2>
                                        <h2 className='text-white letter c2'>c</h2>
                                        <h2 className='text-white letter u2'>u</h2>
                                        <h2 className='text-white letter l2'>l</h2>
                                        <h2 className='text-white letter o6'>o</h2>
                                        <h2 className='text-white letter s5'>s</h2>
                                        <div className='margin'></div>
                                        <h2 className='text-white letter r'>r</h2>
                                        <h2 className='text-white letter e5'>e</h2>
                                        <h2 className='text-white letter c3'>c</h2>
                                        <h2 className='text-white letter e6'>e</h2>
                                        <h2 className='text-white letter n3'>n</h2>
                                        <h2 className='text-white letter t'>t</h2>
                                        <h2 className='text-white letter e7'>e</h2>
                                        <h2 className='text-white letter s6'>s</h2>
                                    </div>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <VeiculosRecentes veiculos={veiculos} />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="product-listing page-section-ptb" style={{ paddingTop: "10px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 banners-sites">
                            <div className="widget-banner">
                                    <img className="img-fluid center-block" style={{marginTop: "10px"}} src="images/trimestrais/01.jpg" alt=""></img>
                                    <img className="img-fluid center-block" style={{marginTop: "10px"}} src="images/trimestrais/02.jpg" alt=""></img>
                                    <img className="img-fluid center-block" style={{marginTop: "10px"}} src="images/trimestrais/03.jpg" alt=""></img>
                                    <img className="img-fluid center-block" style={{marginTop: "10px"}} src="images/trimestrais/04.jpg" alt=""></img>
                                    <img className="img-fluid center-block" style={{marginTop: "10px"}} src="images/trimestrais/05.jpg" alt=""></img>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="col-lg-6">
                                                <TextValidator placeholder="Ex.: Fiat Palio"
                                                    label="Encontre seu veículo"
                                                    onChange={this.handleChange}
                                                    name="buscaVeiculo"
                                                    value={this.state.buscaVeiculo}
                                                    className="form-ui-bootstrap" />

                                            </div>

                                            <div className="col-lg-3" style={{ paddingTop: "42px" }}>
                                                <SelectValidator
                                                    value={tipoVeiculo}
                                                    name="tipoVeiculo"
                                                    className="form-ui-bootstrap select-busca-veiculos"
                                                    onChange={this.handleChange}>
                                                    <MenuItem value="todos">Todos</MenuItem>
                                                    <MenuItem value="novos">0 Km</MenuItem>
                                                    <MenuItem value="seminovos">Seminovos</MenuItem>
                                                </SelectValidator>
                                            </div>

                                            <div className="col-lg-3 text-center"  style={{ paddingTop: "27px" }}>
                                                <button type="submit" className="btn m-btn">BUSCAR VEÍCULOS<span className="fa fa-angle-right"></span></button><br />
                                                <p className="linkLimparBusca text-center" onClick={this.handleClick}>LIMPAR BUSCA</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="row sorting-options">
                                        <div className="col-lg-5">
                                            <div className="change-view-button">
                                                <a className="active" href="#"> <i className="fa fa-th"></i> </a>
                                                <a href="listing-02.html"> <i className="fa fa-list-ul"></i> </a>
                                            </div>
                                        </div> 
                                        
                                    </div>
*/}
                                </ValidatorForm>

                                <div className="row">
                                    {Object.keys(veiculos)
                                        .map(key => {
                                            return (
                                                <div className="col-lg-4" data-wow-delay="0.5s" key={veiculos[key].id}>
                                                    <Link to={"/veiculo/" + veiculos[key].id}>
                                                        <div className="car-item gray-bg text-center">
                                                            <div className="car-image">
                                                                {
                                                                    veiculos[key].imagens.length > 0 ?
                                                                        veiculos[key].imagens[0].fileThumb !== undefined ?
                                                                            <img className="img-fluid" src={veiculos[key].imagens[0].fileThumb} alt=""></img>
                                                                            :
                                                                            <img className="img-fluid" src={veiculos[key].imagens[0].fileUrl} alt=""></img>
                                                                        : <img className="img-fluid" src="/images/car_default.png" alt='imagem veículo'></img>
                                                                }

                                                                <div className="car-overlay-banner"></div>
                                                            </div>
                                                            <div className="car-list">
                                                                <ul className="list-inline">
                                                                    <li><i className="fa fa-registered"></i>{this.state.veiculos[key].veano_fabricacao}/{this.state.veiculos[key].vefipe_ano_modelo}</li>
                                                                    <li><i className="fa fa-cog"></i>{formatCambio(veiculos[key].vecambio)}</li>
                                                                    <li><i className="fa fa-dashboard"></i>{veiculos[key].vequilometragem} KM</li>
                                                                </ul>
                                                            </div>
                                                            <div className="car-content">
                                                                <span>{veiculos[key].veano_fabricacao}/{veiculos[key].vefipe_ano_modelo}</span>
                                                                <Link to={"/veiculo/" + veiculos[key].id}>{veiculos[key].vefipe_marca} {veiculos[key].vefipe_name}</Link>
                                                                <div className="separator"></div>
                                                                <div className="price">
                                                                    <span className="new-price">{formatMoney(veiculos[key].vevalor)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                
                            <section className="welcome-block objects-car page-section-ptb white-bg">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <div className="feature-box text-center">
                                            <div className="icon">
                                                <i className="glyph-icon flaticon-beetle"></i>
                                            </div>
                                            <div className="content">
                                                <h6>Todas as Marcas</h6>
                                                <p>Encontre o carro que você deseja com facilidade, aqui você pode explorar várias opções automotivas. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="feature-box text-center">
                                            <div className="icon">
                                                <i className="glyph-icon flaticon-interface-1"></i>
                                            </div>
                                            <div className="content">
                                                <h6>Atendimento</h6>
                                                <p>Explore nossas redes sociais e nosso suporte online!</p>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div className="col-lg-3 col-md-6">
                                        <div className="feature-box text-center">
                                            <div className="icon">
                                                <i className="glyph-icon flaticon-key"></i>
                                            </div>
                                            <div className="content">
                                                <h6>Venda seu veículo</h6>
                                                <p>Compramos seu veículo seminovo com pagamento à vista e avaliação justa, consulte nossos vendedores.  </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="feature-box text-center">
                                            <div className="icon">
                                                <i className="glyph-icon flaticon-wallet"></i>
                                            </div>
                                            <div className="content">
                                                <h6>Financiamento</h6>
                                                <p>Em parceria com os principais bancos e financeiras, buscamos sempre as melhores taxas de juros para nossos clientes. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="halp-call text-center">
                                            <img className="img-fluid" src="images/perfil.jpg" alt=""></img>
                                            <span>TEM ALGUMA PERGUNTA ?</span>
                                            <h2 className="text-red">(11) 95426-3911</h2>
                                        </div>
                                    </div>
                                </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                

                

                {/*<section className="play-video popup-gallery">
                    <div className="play-video-bg bg-3 bg-overlay-black-70">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-md-12 text-center">
                                    <h3 className="text-white">Que saber mais sobre nós ?</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-12">
                                <div className="video-info text-center">
                                    <img className="img-fluid center-block" src="images/car/24.png" alt=""></img>
                                    <a className="popup-youtube" href="video/video.mp4"> <i className="fa fa-play"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                                </section>*/}

                

                {/*<section className="testimonial-1 white-bg page page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span>Confira nossas loja</span>
                                    <h2>Edcar Veículos </h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="owl-carousel" data-items="3" data-md-items="7" data-sm-items="2" data-xs-items="1" data-space="30">
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja01.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>“O segredo de um grande sucesso está no trabalho de uma grande equipe.” — Murillo Cinta de Oliveira Margarida</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja02.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>“Eu sou parte de uma equipe. Então, quando venço, não sou eu apenas quem vence. De certa forma, termino o trabalho de um grupo enorme de pessoas.” — Ayrton Senna</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja03.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>“Todos são peças importantes no trabalho em equipe. Cada um representa uma pequena parcela do resultado final. Quando um falha, todos devem se unir para a sua reconstrução.” — Salvador Faria</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja04.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>“Quando trabalhamos coletivamente em prol de um objetivo, conquistamos o impossível.” — Jadson Barbosa</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja05.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>“Se as pessoas gostam de você, elas te escutarão, mas se elas confiarem em você, farão negócios com você”. – Zig Ziglar</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja06.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">
                                                {<div className="testimonial-content">
                                                    <p>“Sucesso não é sobre o que você conquista em sua vida, mas sim sobre o que você inspira os demais a fazerem”</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/testimonial/imgloja07.jpg" alt=""></img>
                                            </div>
                                            <div className="testimonial-box">

                                                <div className="testimonial-content">
                                                    <p>“Vender para pessoas que na verdade querem lhe escutar é mais eficiente que interromper aqueles que não querem.” – Seth Godin;</p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                            </section>*/}

                {/*<section className="testimonial-1 white-bg page page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h3>Confira nossos depoimentos</h3>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="owl-carousel" data-items="3" data-md-items="7" data-sm-items="2" data-xs-items="1" data-space="30">
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend01.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend02.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend03.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend04.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend05.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend06.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend07.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend08.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/vend09.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img className="img-fluid" src="images/elogios/ven10.jpg" alt=""></img>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                        </section>*/}
                        
                        
                        
                <Rodape revenda={this.state.revenda} />
            </div>
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Inicio